import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const PresentePage = lazy(() =>
	import("./presente/PresentePage")
);

const ClientePage = lazy(() =>
	import("./cliente/ClientePage")
);

const ProfissionalPage = lazy(() =>
	import("./profissional/ProfissionalPage")
);

const FabricaPlanosPage = lazy(() =>
	import("./fabrica-planos/FabricaPlanosPage")
);

const AcompanhamentoProfissionais = lazy(() =>
	import("./acompanhamentos/profissionais/ProfissionaisPage")
);

const AcompanhamentoBarbearias = lazy(() =>
	import("./acompanhamentos/barbearias/BarbeariasPage")
);

const KpisPage = lazy(() =>
	import("./kpis/KpisPage")
);

const PreviaPage = lazy(() =>
	import("./previa/PreviaPage")
);

const RelatorioPage = lazy(() => 
	import ("./relatorio/RelatorioPage")
);

const FaturamentoConsolidadoPage = lazy(() => 
	import ("./faturamento-consolidado/FaturamentoConsolidadoPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/presentes" component={PresentePage} />
				<Route path="/cliente" component={ClientePage} />
				<Route path="/profissionais" component={ProfissionalPage} />
				<Route path="/fabrica-planos" component={FabricaPlanosPage} />
				<Route path="/acompanhamento/profissionais" component={AcompanhamentoProfissionais} />
				<Route path="/acompanhamento/barbearias" component={AcompanhamentoBarbearias} />
				<Route path="/kpis" component={KpisPage} />
				<Route path="/previa" component={PreviaPage} />
				<Route path="/relatorio" component={RelatorioPage} />
				<Route path="/faturamento-consolidado" component={FaturamentoConsolidadoPage} />

				<Redirect to="/error/error-v6" />
			</Switch >
		</Suspense >
	);
}
