export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Profissionais",
				root: true,
				icon: "fas fa-users",
				page: "profissionais",
				bullet: "dot"
			},
			{
				title: "Fábrica de planos",
				root: true,
				icon: "fas fa-tags",
				page: "fabrica-planos",
				bullet: "dot"
			},
			// {
			// 	title: "Acompanhamentos",
			// 	root: true,
			// 	icon: "fas fa-eye",
			// 	page: "guru",
			// 	bullet: "dot",
			// 	submenu: [
			// 		{
			// 			title: "Profissionais",
			// 			page: "acompanhamento/profissionais",
			// 			bullet: "dot"
			// 		},
			// 		{
			// 			title: "Barbearia",
			// 			page: "acompanhamento/barbearias",
			// 			bullet: "dot"
			// 		},
			// 	]
			// },
			// {
			// 	title: "KPI'S",
			// 	root: true,
			// 	icon: "fas fa-chart-line",
			// 	page: "kpis",
			// 	bullet: "dot",
			// },
			{
				title: "Prévia",
				root: true,
				icon: "fas fa-chart-area",
				page: "previa",
				bullet: "dot",
			},
			{
				title: "Presentes",
				root: true,
				icon: "fas fa-gift",
				page: "presentes",
				bullet: "dot",
			},
			{
				title: "Clientes",
				root: true,
				icon: "fas fa-user",
				page: "cliente",
				bullet: "dot",
			},
			{
				title: "Relatórios",
				root: true,
				icon: "fas fa-chart-pie",
				bullet: "dot",
				submenu: [
					{
						title: "Frequência do cliente",
						page: "relatorio/relatorio01"
					},
					{
						title: "Taxa de retorno",
						page: "relatorio/relatorio02"
					},
				]
			},
			{
				title: "Faturamento consolidado",
				'custom-class': "menu-wrap break-text",
				root: true,
				icon: "fas fa-dollar-sign",
				bullet: "dot",
				submenu: [
					{
						title: "Faturamento",
						page: "faturamento-consolidado/faturamento"
					},
					{
						title: "Crescimento de faturamento",
						page: "faturamento-consolidado/crescimento"
					},
				]
			}
		]
	}
};
