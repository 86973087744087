let url = "";
let cashbarberFrontendUrl = "";

if (process.env.NODE_ENV === 'development') {
	url = "http://localhost:8000";
	cashbarberFrontendUrl = "http://localhost:3000";
} else if (process.env.REACT_APP_STAGE === 'homolog') {
	url = "https://dev.api.cashbarber.com.br";
	cashbarberFrontendUrl = "http://dev.painel.cashbarber.com.br"
} else {
	url = "https://api.cashbarber.com.br";
	cashbarberFrontendUrl = "http://painel.cashbarber.com.br"
}

export const Constants = {
	url: url,
	baseUrl: `${url}/api/pump`,
	cashbarberFrontendUrl: cashbarberFrontendUrl
};
